<template>
  <div v-if="$Can('view_task_report')">
    <b-card>

      <b-card-header>
        <b-card-title class="d-flex justify-content-between align-items-center w-100">
          <div class="d-flex align-items-center">

            {{ selectedReport == 'Time Report' ? 'Task Time Report' : 'Task Clinic Report' }}
        <b-dropdown id="dropdown-time-clinic-report" text="Time Report" variant="link" class="ml-2">
          <template #button-content>
            <span>{{ selectedReport }}</span>
            <i class="fa fa-chevron-down ml-2"></i>
          </template>
          <b-dropdown-item @click="selectReport('Time Report')">Time Report</b-dropdown-item>
          <b-dropdown-item @click="selectReport('Clinic Report')">Clinic Report</b-dropdown-item>
        </b-dropdown>
        </div>
        <div>
          <b-button class="ml-5" variant="success" @click="downloadPDF"
              >Download PDF</b-button
            >
        </div>
      </b-card-title>
    </b-card-header>

      <div
        class="mb-2 text-center d-flex justify-content-center align-items-center"
      >
        <b-row class="">
          <b-col cols="12" lg="4">
            <treeselect
              v-model="selectedDepartments"
              @input="selectedFilters"
              value-consists-of="LEAF_PRIORITY"
              :limit="3"
              :multiple="true"
              :options="departmentOptions"
            />
          </b-col>

          <b-col cols="12" lg="4">
            <treeselect
              v-model="selectedTasks"
              @input="selectedFilters"
              value-consists-of="LEAF_PRIORITY"
              :limit="3"
              :multiple="true"
              :options="taskOptions"
            />
          </b-col>

          <b-col cols="12" lg="4">
            <flat-pickr
              v-model="selectedDates"
              class="form-control bg-white w-100"
              :config="datePickerConfig"
            />
          </b-col>

          <b-col cols="12" lg="12" class="mt-50">
            <b-button class="mr-1" variant="info" @click="selectThisWeek"
              >This Week</b-button
            >
            <b-button class="mr-1" variant="info" @click="selectThisMonth"
              >This Month</b-button
            >
            <b-button class="mr-1" variant="info" @click="selectLastMonth"
              >Last Month</b-button
            >
            <b-button class="mr-1" variant="info" @click="selectNextMonth"
              >Next Month</b-button
            >
            <b-button class="mr-1" variant="info" @click="selectThisYear"
              >This Year</b-button
            >
        
          </b-col>
        </b-row>
      </div>

      <b-row>
        <b-col md="12">
          <b-card>
            <canvas id="dailyTasksChart" width="400" height="200"></canvas>
          </b-card>
        </b-col>
      </b-row>

      <!-- <b-row class="mt-3">
        <b-col md="6">
          <b-card>
            <canvas id="taskDistributionByPriorityChart" width="400" height="200"></canvas>
          </b-card>
        </b-col>

        <b-col md="6">
          <b-card>
            <canvas id="taskCompletionRadarChart" width="400" height="200"></canvas>
          </b-card>
        </b-col>
      </b-row> -->
      <b-row>
        <b-col>
          <div class="d-flex justify-content-end mb-2">
            <b-button variant="success" @click="taskReportDataExport"
              >Export .xlsx</b-button
            >
          </div>
        </b-col>
      </b-row>

      <b-row>
        <TaskReportTable :taskReportTable="taskReportTable" />
      </b-row>

    </b-card>


  </div>
  <div v-else class="text-center mt-5 text-danger">
    No Permission
  </div>
</template>

<script>
import reportStoreModule from "./reportStoreModule";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import Chart from "chart.js";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import TaskReportTable from "./TaskReportTable.vue";



export default {
  setup() {
    const TASK_REPORT_APP_STORE_MODULE_NAME = "task-report";
    if (!store.hasModule(TASK_REPORT_APP_STORE_MODULE_NAME))
      store.registerModule(
        TASK_REPORT_APP_STORE_MODULE_NAME,
        reportStoreModule
      );
    onUnmounted(() => {
      if (store.hasModule(TASK_REPORT_APP_STORE_MODULE_NAME))
        store.unregisterModule(TASK_REPORT_APP_STORE_MODULE_NAME);
    });
  },

  components: {
    flatPickr,
    Treeselect,
    TaskReportTable
  },

  data() {
    return {
      taskReportTable:[],
      selectedReport:'Time Report',
      selectedDates: [],
      departmentOptions: [],
      taskOptions: [],
      selectedDepartments: [],
      selectedTasks: [],
      datePickerConfig: {
        dateFormat: "d/m/Y",
        mode: "range",
        locale: {
          firstDayOfWeek: 1, // Monday
        },
        onChange: this.onDateChange,
      },
      summary: [],
      charts: {
        taskDistributionByPriorityChart: null,
        dailyTasksChart: null,
        taskCompletionRadarChart: null,
      },
    };
  },

  created() {
    if (this.$Can("view_task_report")) this.setDefaultDates();
  },

  methods: {

    selectReport(report){
      this.selectedReport = report;
      this.renderCharts();
    },
    selectedFilters() {
      if (!this.selectedDates || this.selectedDates.length === 0) {
        return;
      }

      let dates = [];

      if (
        typeof this.selectedDates === "string" &&
        this.selectedDates.includes(" to ")
      ) {
        const [startDateStr, endDateStr] = this.selectedDates.split(" to ");

        const startDate = this.parseDate(startDateStr);
        const endDate = this.parseDate(endDateStr);

        dates = [startDate, endDate];
      } else if (Array.isArray(this.selectedDates)) {
        dates = this.selectedDates.map((date) => new Date(date));
      }

      // Tarihleri ISO formatına çevir
      const formattedDates = dates.map(
        (date) => date.toISOString().split("T")[0]
      );

      this.fetchTaskSummary(formattedDates);
    },

    parseDate(dateStr) {
      const [day, month, year] = dateStr.split("/").map(Number);
      return new Date(year, month - 1, day);
    },

    setDefaultDates() {
      const now = new Date();
      const firstDayOfWeek = now.getDate() - now.getDay() + 1; // Monday
      const lastDayOfWeek = firstDayOfWeek + 6; // Sunday
      const firstDay = new Date(now.setDate(firstDayOfWeek));
      const lastDay = new Date(now.setDate(lastDayOfWeek));
      this.selectedDates = [firstDay, lastDay];

      this.fetchTaskSummary(this.selectedDates);
    },

    onDateChange(selectedDates) {
      if (
        JSON.stringify(this.selectedDates) !== JSON.stringify(selectedDates)
      ) {
        this.selectedDates = selectedDates;
        this.fetchTaskSummary(selectedDates);
      }
    },

    fetchTaskSummary(selectedDates) {
      if (selectedDates.length !== 2) return;

      let [startDate, endDate] = selectedDates;
      startDate = new Date(startDate).toISOString().split("T")[0];
      endDate = new Date(endDate).toISOString().split("T")[0];

      store
        .dispatch("task-report/taskReport", {
          startDate,
          endDate,
          selectedDepartments: this.selectedDepartments,
          selectedTasks: this.selectedTasks,
        })
        .then((res) => {
          this.summary = res.data.data;
          this.departmentOptions = res.data.departments;
          this.taskOptions = res.data.tasks;
          this.renderCharts();
        })
        .catch((error) => {
          this.$swal({
            title: "Error!",
            text: "Report could not be loaded!" + error,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },

    renderCharts() {
      // this.renderTaskDistributionByPriorityChart();
      this.renderDailyTasksChart();
      this.createTaskBreakdown();
      // this.renderTaskCompletionRadarChart();
    },

    taskReportDataExport() {
      let reportData =  this.taskReportTable.map(task => {
        return {
          date: task.date,
          task_title: task.task_title,
          count: task.count,
          total_minutes: task.total_minutes
        };
      });

      store
        .dispatch("task-report/taskReportDataExport", { datas: reportData })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: "application/octet-stream",
          });

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "task_report.xlsx");
          document.body.appendChild(link);
          link.click();
          this.loading = false;
        })
        .catch((error) => {
          this.$swal({
            title: "Error!",
            text: "Report could not be loaded!" + error,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },

    renderTaskDistributionByPriorityChart() {
      if (this.charts.taskDistributionByPriorityChart) {
        this.charts.taskDistributionByPriorityChart.destroy();
      }

      const ctx = document
        .getElementById("taskDistributionByPriorityChart")
        .getContext("2d");

      const taskCounts = this.summary.reduce((acc, item) => {
        item.tasks.forEach((task) => {
          acc[task.task_title] =
            (acc[task.task_title] || 0) + task.total_minutes;
        });
        return acc;
      }, {});

      const labels = Object.keys(taskCounts);
      const data = Object.values(taskCounts);
      const backgroundColor = labels.map((label) => {
        const task = this.summary
          .find((item) => item.tasks.find((task) => task.task_title === label))
          .tasks.find((task) => task.task_title === label);
        return task ? task.color.bgColor : "#ccc"; // Eşleşen renk yoksa default bir renk kullan
      });

      this.charts.taskDistributionByPriorityChart = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels,
          datasets: [
            {
              label: "Total Task Distribution by Priority",
              data,
              backgroundColor,
            },
          ],
        },
        options: {
          responsive: true,
        },
      });
    },

    renderDailyTasksChart() {
  if (this.charts.dailyTasksChart) {
    this.charts.dailyTasksChart.destroy();
  }

  const ctx = document.getElementById("dailyTasksChart").getContext("2d");

  const dates = this.summary.map((item) => item.date);
  const tasksData = {};

  // Initialize tasksData with an empty array for each date
  this.summary.forEach((item) => {
    item.tasks.forEach((task) => {
      if (!tasksData[task.task_title]) {
        tasksData[task.task_title] = new Array(dates.length).fill(0);
      }
    });
  });

  // Fill tasksData with actual values
  this.summary.forEach((item, dateIndex) => {
    item.tasks.forEach((task) => {
      tasksData[task.task_title][dateIndex] = this.selectedReport === 'Time Report' ? task.total_minutes : task.count;
    });
  });

  const datasets = Object.keys(tasksData).map((task_title) => {
    const taskColor = this.summary
      .flatMap(item => item.tasks)
      .find(task => task.task_title === task_title)?.color?.bgColor || '#000000';

    return {
      label: task_title,
      data: tasksData[task_title].map((value, index) => ({
        x: dates[index],
        y: value,
        hidden: value === 0,
      })),
      backgroundColor: taskColor,
      borderColor: taskColor,
      fill: false,
    };
  });

  this.charts.dailyTasksChart = new Chart(ctx, {
    type: "bar",
    data: {
      labels: dates,
      datasets,
    },
    options: {
      responsive: true,
      hover: {
        animationDuration: 0,
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem, data) {
            const dataset = data.datasets[tooltipItem.datasetIndex];
            const value = dataset.data[tooltipItem.index].y;
            if (value !== 0) {
              if (this.selectedReport === 'Time Report') {
                const hours = Math.floor(value / 60);
                const minutes = (value % 60).toString().padStart(2, "0");
                return `${dataset.label}: ${hours}:${minutes}`;
              } else {
                return `${dataset.label}: ${value}`;
              }
            }
            return "";
          }.bind(this),
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: function (value) {
                if (this.selectedReport === 'Time Report') {
                  const hours = Math.floor(value / 60);
                  const minutes = (value % 60).toString().padStart(2, "0");
                  return `${hours}:${minutes}`;
                } else {
                  return value;
                }
              }.bind(this),
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              maxTicksLimit: 12,
            },
          },
        ],
      },
      legend: {
        display: true,
      },
    },
  });
},

    renderTaskCompletionRadarChart() {
      if (this.charts.taskCompletionRadarChart) {
        this.charts.taskCompletionRadarChart.destroy();
      }

      const ctx = document
        .getElementById("taskCompletionRadarChart")
        .getContext("2d");

      const taskCounts = this.summary.reduce((acc, item) => {
        item.tasks.forEach((task) => {
          acc[task.task_title] =
            (acc[task.task_title] || 0) + task.total_minutes;
        });
        return acc;
      }, {});

      const labels = Object.keys(taskCounts);
      const data = Object.values(taskCounts);

      this.charts.taskCompletionRadarChart = new Chart(ctx, {
        type: "radar",
        data: {
          labels,
          datasets: [
            {
              label: "Task Completion",
              data,
              backgroundColor: "rgba(54, 162, 235, 0.2)",
              borderColor: "rgba(54, 162, 235, 1)",
              pointBackgroundColor: "rgba(54, 162, 235, 1)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgba(54, 162, 235, 1)",
            },
          ],
        },
        options: {
          responsive: true,
        },
      });
    },

    selectThisWeek() {
      const now = new Date();
      const firstDayOfWeek = now.getDate() - now.getDay() + 1; // Monday
      const lastDayOfWeek = firstDayOfWeek + 6; // Sunday
      const dates = [
        new Date(now.setDate(firstDayOfWeek)),
        new Date(now.setDate(lastDayOfWeek)),
      ];
      this.selectedDates = dates;
      this.fetchTaskSummary(dates);
    },

    selectThisMonth() {
      const now = new Date();
      const dates = [
        new Date(now.getFullYear(), now.getMonth(), 1),
        new Date(now.getFullYear(), now.getMonth() + 1, 0),
      ];
      this.selectedDates = dates;
      this.fetchTaskSummary(dates);
    },

    selectLastMonth() {
      const now = new Date();
      const dates = [
        new Date(now.getFullYear(), now.getMonth() - 1, 1),
        new Date(now.getFullYear(), now.getMonth(), 0),
      ];
      this.selectedDates = dates;
      this.fetchTaskSummary(dates);
    },

    selectNextMonth() {
      const now = new Date();
      const dates = [
        new Date(now.getFullYear(), now.getMonth() + 1, 1),
        new Date(now.getFullYear(), now.getMonth() + 2, 0),
      ];
      this.selectedDates = dates;
      this.fetchTaskSummary(dates);
    },

    selectThisYear() {
      const now = new Date();
      const dates = [
        new Date(now.getFullYear(), 0, 1),
        new Date(now.getFullYear(), 11, 31),
      ];
      this.selectedDates = dates;
      this.fetchTaskSummary(dates);
    },

    convertMinutesToHoursMinutes(minutes) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours}:${remainingMinutes.toString().padStart(2, "0")}`;
    },
    convertDateString(dateString) {
      const dateParts = dateString.split("-");
      const year = dateParts[0];
      const month = dateParts[1].padStart(2, "0"); // Ensure two digits for month
      const day = dateParts[2].padStart(2, "0"); // Ensure two digits for day

      return `${day}-${month}-${year}`;
    },

    createTaskBreakdown(){

      let dates = null;
      if (
        typeof this.selectedDates === "string" &&
        this.selectedDates.includes(" to ")
      ) {
        const [startDateStr, endDateStr] = this.selectedDates.split(" to ");

        const startDate = this.parseDate(startDateStr);
        const endDate = this.parseDate(endDateStr);

        dates = [startDate, endDate];
      } else {
        dates = this.selectedDates;
      }
      const taskAnalysisTable = this.summary
  .map((main) => {
    return main.tasks.map((task) => {
      task.date = this.convertDateString(main.date);
      task.converted_total_minutes = this.convertMinutesToHoursMinutes(task.total_minutes);
      return task;
    });
  })
  .flat();

// Calculate the total task_count and total_minutes (in minutes)
const totalTaskAnalysisCount = taskAnalysisTable.reduce((total, item) => total + item.count, 0);
const totalAnalysisMinutes = taskAnalysisTable.reduce((total, item) => {
  const [hours, minutes] = item.converted_total_minutes.split(':').map(Number);
  return total + (hours * 60) + minutes;
}, 0);

// Create the summary row
const summaryAnalysisRow = {
  count: totalTaskAnalysisCount,
  date: 'Total',
  task_title: '',
  converted_total_minutes: this.convertMinutesToHoursMinutes(totalAnalysisMinutes), // Convert total minutes to hours:minutes
  task_id: '',
};

taskAnalysisTable.push(summaryAnalysisRow);

// Remove converted_total_minutes from the taskAnalysisTable items before using it
this.taskReportTable = taskAnalysisTable.map(item => {
  const { converted_total_minutes, ...rest } = item;
  rest.total_minutes = item.converted_total_minutes;
  return rest;
});
    },


    downloadPDF() {
     
      let dates = null;
      if (
        typeof this.selectedDates === "string" &&
        this.selectedDates.includes(" to ")
      ) {
        const [startDateStr, endDateStr] = this.selectedDates.split(" to ");

        const startDate = this.parseDate(startDateStr);
        const endDate = this.parseDate(endDateStr);

        dates = [startDate, endDate];
      } else {
        dates = this.selectedDates;
      }
      store
        .dispatch("task-report/downloadPDF2", {
          datePeriod:
            dates[0].toDateString() + " to " + dates[1].toDateString(),
          departments: this.departmentOptions
            .filter((e) => {
              return this.selectedDepartments.includes(e.id);
            })
            .map((e) => {
              return e.label;
            })
            .toString(),
          dailyTasksChart: document
            .getElementById("dailyTasksChart")
            .toDataURL(),
          taskAnalysisTable: this.taskReportTable,
          type:this.selectedReport
        })
        .then((res) => {
          if (res.status == 200) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "dashboard-report.pdf");
            document.body.appendChild(link);
            link.click();

            this.$swal({
              title: "Succesfull!",
              text: "✔️ Report is created!",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching user report:", error);
        });
    },
  },
};
</script>

<style scoped>
canvas {
  max-width: 100%;
}

.total-tasks-display h2 {
  font-size: 4em;
  color: #42a5f5;
  text-align: center;
}

.flat-pickr-wrapper {
  display: flex;
  align-items: center;
}

.flat-pickr-wrapper .form-control {
  margin-right: 1rem;
}

.flat-pickr-wrapper .d-flex {
  flex-wrap: wrap;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
