<template>
  <div>
    <b-tabs horizontal pills fill v-model="activeTab">
      <b-tab title="⚙️ Dashboard">
        <dashboard-graphs v-if="activeTab == 0"></dashboard-graphs>
      </b-tab>
      <b-tab title="📜 Task Report">
        <task-report v-if="activeTab == 1"></task-report>
      </b-tab>
      <b-tab title="👤 User Report">
        <user-report v-if="activeTab == 2"></user-report>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import DashboardGraphs from './DashboardGraphs.vue';
import TaskReport from './TaskReport.vue';
import UserReport from './UserReport.vue';

export default {
  components: {
    DashboardGraphs,
    TaskReport,
    UserReport,
  },

  mounted() {},

  data() {
    return {
      activeTab: 0,
    };
  },

  methods: {},
};
</script>
