import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchWeeks(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('reports/fetchWeeks', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    dashboardSummary(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('reports/dashboard-summary', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    taskReport(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('reports/task-report', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    userReport(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('reports/user-report', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    downloadPDF(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('reports/download-pdf', data, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    downloadPDF2(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('reports/download-pdf2', data, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    downloadPDF3(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('reports/download-pdf3', data, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    taskDataExport(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post('reports/taskDataExport',data ,{ responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    userDataExport(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post('reports/userDataExport',data ,{ responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    taskReportDataExport(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post('reports/taskReportDataExport',data ,{ responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },


    
    
    

  },
};
